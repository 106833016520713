@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-shadow {
    border-radius: 8px;
    background: var(--white-white-1, #FFF);
    box-shadow: 0px 5px 20px 0px rgba(40, 43, 89, 0.10);
}

.store-download-shadow {
    border-radius: 15px;
    border: 2px solid var(--primary-color-blue-7, #A9AABD);
    background: var(--white-white-1, #FFF);
}

.large-text-typography {
    color: var(--primary-color-blue-1, #282B59);
    font-size: 25px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.small-text-typography {
    color: var(--primary-color-blue-3, #53557A);
    text-align: center;
    font-size: 18px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.dark-large-text-typography {
    color: var(--primary-color-blue-1, #282B59);
    text-align: center;
    font-size: 20px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.terms-text-small {
    color: #53557A;
    font-family: Nunito;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}